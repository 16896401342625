.container {
  width: 420px;
  background: var(--bg-secondary);
  display: flex;
  flex-direction: column;
  font-family: Ubuntu-R;
  height: 100%;
}

.table {
  border-collapse: collapse;
}

.rowItem {
  cursor: pointer;
}

.item {
  width: 100%;
}

.defaultItem {
  padding: 5px 0 5px 20px;
}
.groupItem {
  padding: 5px 0 5px 16px;
}

.rowItem:hover {
  color: var(--color-brand);
  background: #b2ebf259;
}

.rowItem.active {
  background: rgb(176 225 223);
}

.itemName {
  position: relative;
  z-index: 1;
  width: fit-content;
}

.rowItem:hover > td .underline {
  border-bottom: 1px solid #bbbbbb;
}

.rowItem:hover > .actionButtons {
  visibility: visible;
}

.actionButtonIconContainer {
  width: 17px;
  height: 17px;
}

.actionButton {
  margin: 6px 0 7px;
  cursor: pointer;
}

.actionButtons {
  color: var(--text-primary);
  display: flex;
  float: right;
  margin-right: 10px;
  gap: 2px;
  visibility: hidden;
}

.expandIcon {
  margin: 2px 0 0 2px;
  transform: rotate(-90deg);
  cursor: pointer;
}

.expandedIcon {
  transform: rotate(0);
}

.nestedItem {
  padding: 5px 0 5px 56px;
}

.expandButton {
  margin: 0;
  padding: 0 5px 0 0;
}

.noDataRow {
  padding: 5px 0 5px 56px;
  user-select: none;
  color: gray;
}