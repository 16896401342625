.itemContainer {
  display: grid;
  grid-template-columns: auto 1fr 42px;
}

.operationTextContainer {
  width: 100%;
  position: relative;
}

.textarea::placeholder {
  font-weight: normal;
}

.textarea {
  font-family: Ubuntu-R;
  font-weight: 600;
  font-size: 16px;
  border: none;
  width: 100%;
  background: transparent;
  resize: none;
  display: inline-table;
  padding: 0;
}

.indexContainer {
  padding: 0 30px 0 0;
}

.index {
  font-size: 24px;
  color: var(--text-secondary);
}

.operationContainer {
  display: flex;
  gap: 20px;
}

.timer {
  display: flex;
  font-size: 14px;
  font-weight: normal;
  white-space: nowrap;
  gap: 10px;
  height: 100%;
  padding-right: 14px;
}

.deleteIconContainer {
  border-radius: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #b0e1df;
  width: 26px;
  height: 26px;
  cursor: pointer;
  margin: 0 0 0 16px;
}

.removeDeviceIconContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  margin: 0 0 0 10px;
}

.title {
  font-size: 14px;
  color: gray;
  padding: 20px 0 6px;
  border-bottom: 1px solid gray;
  opacity: 0.5;
  width: 100%;
  user-select: none;
}

.arrowIcon {
  width: 20px;
  height: 20px;
}

.rotatedArrow {
  transform: rotate(180deg);
}

.arrowIconContainer {
  display: none;
  margin-top: 6px;
  background: transparent;
  cursor: pointer;
}

.itemContainer:hover .arrowIconContainer {
  display: block;
}

.addButtonContainer {
  background: var(--color-brand);
  border-radius: 50%;
  cursor: pointer;
  padding: 6px;
}

.addButton {
  width: 12px;
  height: 12px;
}

.addDeviceButtonContainer {
  display: flex;
  height: min-content;
  margin: 20px 20px 0 0;
  align-items: center;
  text-align: center;
  padding: 0;
  gap: 10px;
  font-family: Ubuntu-R;
  font-size: 15px;
  letter-spacing: 0.04em;
  color: var(--border-gray);
  cursor: pointer;
  width: fit-content;
}

.addDeviceButtonContainer:hover {
  text-decoration: underline;
}

.addDeviceSelectContainer {
  position: absolute;
  left: 0;
  top: 35px;
  z-index: 1000;
  border-radius: 5px;
  box-shadow: 0 5px 10px 5px rgb(89 102 109 / 17%);
  box-sizing: border-box;
  height: 200px;
  width: 340px;
  background: var(--bg-textarea);
  overflow: auto;
  padding: 16px;
}

.addDevice {
  position: relative;
}

.dataErrorBlock {
  position: absolute;
  display: flex;
  margin: -3px 0 0;
}

.dataError {
  color: #c12d2d;
  font-family: Ubuntu-R;
  font-size: 14px;
  letter-spacing: 0.04em;
  line-height: 1.7;
  margin-left: 5px;
}

.timerValue {
  font-weight: 600;
}

.deviceOperationContainer {
  display: flex;
  align-items: center;
}
