.pageContainer {
  height: 100%;
}

.toolbar {
  display: flex;
  padding: 15px 40px;
  column-gap: 10px;
}

.protocolContainer {
  padding: 20px 40px;
  overflow-y: scroll;
  height: calc(100% - 94px - 20px);
}

.protocolInput {
  width: 100%;
  padding: 10px;
  font-size: 16px;
  border: 1px solid #ccc;
  border-radius: 5px;
}

.header {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 20px;
  width: auto;
}

.label {
  margin-right: 50px;
  font-size: 16px;
  font-family: Ubuntu-M;
  position: relative;
}

.formFieldContainer {
  display: flex;
  max-width: 607px;
  width: 100%;
  height: 100%;
  margin: 0 0 15px;
}

.formField {
  width: 100%;
  position: relative;
}

.title {
  width: 365px;
  text-align: center;
  padding: 12px 0;
  margin: 0 0 30px;
  font-size: 20px;
  font-family: Ubuntu-R;
  background-color: #f1f7fc;
  border-radius: 10px;
  justify-content: center;
}

.operationBlock {
  box-sizing: border-box;
  background: #f4f7fc;
  width: 100%;
  padding: 15px;
  margin: 10px 0;
  border-radius: 10px;
}

.deleteIconProtocol {
  background-image: url('assets/svg/delete-01.svg');
  background-color: white;
  height: 34px;
  width: 34px;
  border: 1px solid #1aba66;
  border-radius: 50%;
  background-repeat: no-repeat;
  background-position: center;
  cursor: pointer;
}

.addOperationBlock {
  display: flex;
  justify-content: center;
}

.addOperationButton {
  font-family: Ubuntu-R;
  background: #f4f7fc;
  color: var(--color-brand);
  display: flex;
  align-items: center;
  padding: 4px 26px 4px 10px;
  justify-content: space-evenly;
  border-radius: 100px;
  height: 36px;
  cursor: pointer;
  position: relative;
  gap: 4px;
  border: 1px solid var(--color-brand);
  font-size: 14px;
  font-weight: 600;
}

.addIconContainer {
  padding: 4px;
}

.dataErrorBlock {
  position: absolute;
  display: flex;
  top: 35px;
}

.dataError {
  color: #c12d2d;
  font-family: Ubuntu-R;
  font-size: 14px;
  letter-spacing: 0.04em;
  line-height: 1.7;
  margin-left: 5px;
}
