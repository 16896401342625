.container {
  width: calc(100% - 420px);
}
.noContent {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  color: gray;
}
