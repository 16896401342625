.toolBar {
  padding: 15px;
  display: flex;
}

.defaultActions {
  display: flex;
  gap: 10px;
  width: 100%;
  align-items: center;
}

.searchInputContainer {
  background: #f4f7fc;
  border-radius: 100px;
  height: 30px;
  width: 100%;
  display: flex;
  align-items: center;
  max-width: 400px;
  position: relative;
}

.searchIcon {
  height: 15px;
  padding: 0 6px 0 7px;
}

.searchInput {
  background: #f4f7fc;
  width: 100%;
  border-radius: 0 100px 100px 0;
  border: none;
  font-size: 15px;
  font-family: Ubuntu-R;
}

.icon {
  width: 25px;
  height: 25px;
}

.addButton {
  font-family: Ubuntu-R;
  background: var(--color-brand);
  color: white;
  display: flex;
  align-items: center;
  padding: 0 10px;
  justify-content: space-evenly;
  border-radius: 100px;
  height: 36px;
  cursor: pointer;
  position: relative;
  font-size: 15px;
  gap: 4px;
}

.addIcon {
  margin: 2px 0 0 2px;
}

.openedAddingMenu {
  transform: rotate(180deg);
}

.addingMenuContainer {
  position: absolute;
  right: 0;
  top: 35px;
  z-index: 1000;
  border-radius: 5px;
  box-shadow: 0 5px 10px 5px rgb(89 102 109 / 17%);
  box-sizing: border-box;
  height: auto;
  width: auto;
  white-space: nowrap;
  background: var(--bg-textarea);
  overflow: auto;
  padding: 10px 0;
}

.addingMenu {
  position: relative;
}

.addingMenuItem {
  align-items: center;
  color: var(--text-primary);
  display: flex;
  font-family: Ubuntu-R;
  height: 30px;
  min-height: 30px;
  padding-left: 5px;
  position: relative;
  cursor: pointer;
  padding: 4px 16px;
  font-size: 15px;
}

.addingMenuItem img {
  width: 25px;
  height: 16px;
  margin-right: 10px;
}

.addingMenuItem:hover {
  background: #a2e3df;
}

.refreshBlock {
  background: var(--color-brand);
  border-radius: 100px;
  height: 35px;
  width: 35px;
  cursor: pointer;
  position: relative;
}

.refresh {
  mask-image: url('../../../assets/svg/refresh-01.svg');
  background-color: white;
  height: 36px;
  width: 35px;
  mask-repeat: no-repeat;
  mask-repeat: no-repeat;
  mask-position: center;
  mask-position: center;
}

.tooltip {
  position: absolute;
  top: 34px;
  left: auto;
  right: 0;
  width: auto;
  display: none;
  background-color: #f9f9f9;
  padding: 10px;
  margin-right: 10px;
  border-radius: 10px;
  z-index: 4000;
  color: var(--border-gray);
}

.refreshBlock:hover .tooltip {
  display: block;
}