.modalContainer {
  position: fixed;
  top: 0;
  right: 0;
  z-index: 300;
  background: rgb(19 57 43 / 54%);
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.headerTitle {
  margin: 0 0 0 10px;
  font-size: 25px;
}

.modal {
  font-size: 17px;
  width: 680px;
  padding: 15px;
  background: var(--bg-secondary);
  border-radius: 5px;
  display: grid;
  align-content: space-between;
  grid-template-rows: 60px auto 50px;
}

.modalBody {
  padding: 20px 40px 20px 20px;
  height: 40px;
  max-height: 1400px;
}

.footer {
  margin: 10px auto;
  display: flex;
}

.rowType {
  margin: 20px 0;
}

.dataErrorBlock {
  display: flex;
  margin: 20px 0 0;
}

.closeButton {
  border-radius: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: white;
  margin: 10px;
  width: 35px;
  height: 35px;
  cursor: pointer;
  z-index: 99999999;
}

.formField {
  margin: 420px 0 40px;
}
