.deviceItemContainer {
  margin: 10px 0 0;
  padding: 10px;
  border: solid 1px #b0c3c8;
  flex-grow: 1;
}

.dataErrorBlock {
  display: flex;
  margin: 20px 0 0;
}

.infoItem {
  width: 100%;
  margin-bottom: 20px;
  color: var(--text-primary);
  display: grid;
  grid-template-columns: 40% 60%;
}

.infoItem div:first-child {
  max-width: 280px;
}

.headerTitle {
  margin: 0 0 0 10px;
  font-size: 25px;
}

.categoryTitle {
  margin: 20px auto;
  border: 2px dashed gray;
  text-align: center;
  padding: 10px 0;
}

.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.standartFieldContainerFirst {
  margin: 10px 0;
}

.standartFieldContainer {
  margin: 40px 0 10px;
}

.dataError {
  color: #c12d2d;
  font-family: Ubuntu-R;
  font-size: 14px;
  letter-spacing: 0.04em;
  line-height: 1.7;
  margin-left: 5px;
}

.dataErrorBlock {
  position: absolute;
  display: flex;
  margin: -3px 0 0;
}

.dataErrorBlockDinamyc {
  margin: -22px 0 0;
  right: 60px;
}

.userFieldContainer {
  position: relative;
}

.itemImg {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.containerImg {
  width: 72px;
  height: 72px;
  overflow: hidden;
}

.itemIcon {
  border: 1px solid rgb(0 0 0 / 50.4%);
  height: 91px;
  display: grid;
  place-items: center center;
  padding: 2px;
}

.iconsContainer {
  width: 120px;
  max-height: 420px;
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  overflow-y: scroll;
  gap: 10px;
}

.devicePreview {
  display: flex;
  align-items: center;
}

.hiddenRow {
  transform: rotate(-90deg);
}

.expandButton {
  cursor: pointer;
  margin: 0 6px 0 0;
}

.deviceInfoContainer {
  display: flex;
  margin: 20px 0 0;
  padding: 0 6px;
}
